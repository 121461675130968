import React, { useState, useEffect } from 'react';
import $ from 'jquery'
import { useMediaQuery } from 'react-responsive'
import {Switch, Route, Link,useLocation} from "react-router-dom"
import { withRouter } from "react-router"
import BackButton from "./BackButton"


//import { history as historyShape } from 'react-router-prop-types'

/* Images and media files */
import { ReactComponent as Logo }  from './../media/logo.svg';

// TODO: use ref
let isOpen = false;
let toggleMenu = () => {

    if($("body").hasClass("menu-is-open")){
        $("body").removeClass("menu-is-open");
    }else{
        $("body").addClass("menu-is-open");
    }
}




const Header = () => {


    let location = useLocation();
    let [displayStartBtn,setDsiplaystartBtn] = useState(false)
    let [hideHeaderMenu,setHideHeaderMenu] = useState(false);
    useEffect(() => {
        $("body").removeClass("menu-is-open");
        switch (location.pathname.split("/")[1]) {
            case "start":
            case "load":
            case "order":
                setHideHeaderMenu(true);
                setDsiplaystartBtn(false);
                break;
            default:
                setHideHeaderMenu(false);
                setDsiplaystartBtn( true )
                break;
        }

    }, [location]);

/*
    history.listen((location, action) => {
        // location is an object like window.location
        console.log(location)
    });*/



    const isDesktop = useMediaQuery({
        query: '(min-width: 960px)'
    })


    const [isHome, setIsHome] = useState(false);


    const let_s_start_btn = displayStartBtn&&(<Link className="button small-button sticky-btn" to="/start" >Let's start</Link>);



    return (
        <div className={"header-bar"}>
            <div className={"header-item header-back"}>
                {!isDesktop&& (<BackButton/>)}
            </div>
            <div className={"header-item header-burger"}>
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="hamburger-box">
                        <div className="hamburger-inner"></div>
                    </div>
                </div>
            </div>
            <div className={"header-item header-menu"}>
                {!hideHeaderMenu&&(
                <ul>
                    <li>
                        <Link to={"about-us"}>About us</Link>
                    </li>
                    <li>
                        <Link to={"tips"}>Tips</Link>
                    </li>
                    <li>
                        <Link to={"faq"}>FAQ</Link>
                    </li>
                </ul>)}
            </div>
            <div className={"header-item header-logo"}>
                <Link to="/" >
                    <div className={"logo"}>
                        <Logo/>
                    </div>
                </Link>
            </div>
            <div className={"header-item white-btn header-button"}>
                <Link className="button white-btn small-button" href={"mailto:contact@flipflip.io"} >Contact</Link>
            </div>
        </div>

    );
}

export default withRouter(Header);