import React, {useRef} from 'react';
import { Link } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import book_cover from './../media/cover-bg.jpg';




function TitleInputField(props) {

    const textareaRef = useRef();

    const CHARACTER_LIMIT = 50;

    const [values, setValues] = React.useState({
            name: "",
            printlines: "",
            display: "",
    });

    React.useEffect(() => {
        textareaRef.current.focus();
    })

    const handleChange = name => event => {

            //let len = event.target.value.length;
            //let title = event.target.value;
            let printlines = event.target.value.replace(/(.{1,17})(?:\n|$| )/g, "$1\n");
            //let display = printlines.split ('\n').map ((item, i) => <div>{item}<br/></div>);

            setValues({ 'printlines': printlines });
    };





	return (
	    <React.Fragment>
            <div className={"flip-book-conf"}>
                <div className={"conf-container"}>
                    <img className={"conf-book"} src={book_cover} alt="Bookcover without title" style={{width:"100%"}} />
                    <TextareaAutosize ref={textareaRef} spellcheck="false" onChange={handleChange} placeHolder={'Enter Title here"'} className={"conf-title"}>{values.name}</TextareaAutosize>
                </div>
            </div>
            <Link disable={!props.id&&"disabled"} className={(props.id&&"is-actived")+" button page-action-button btn-create-flipbook"} to={{ pathname: "/load/"+props.id, state: {id: props.id, title: values.printlines} }}>
                Create your flipbook
            </Link>
        </React.Fragment>
	)

}
export default TitleInputField;
