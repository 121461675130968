import React,{ useState, useEffect, useRef }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import "./../../node_modules/video-react/dist/video-react.css"; // import css
import { useLocation } from 'react-router-dom'
import {$} from 'jquery';
import Logo from './../components/Logo';
import Grid, { GridSpacing }  from '@material-ui/core/Grid'
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller  } from 'react-hook-form';
import image_stripe from './../media/powered_by_stripe.png';
import SelectCountry from "../components/SelectCountry";
import { COUNTRY_MenuItemS } from './../countriesData.js';
import {Container, MenuItem} from "@material-ui/core";


const FLP_API_CHECKOUT_URL = "https://hgeo9o8jl5.execute-api.eu-west-3.amazonaws.com/test0/checkout"
//const STRIPE_API_URL = "pk_test_8SKjH1OG6pQMbXDDIsSI6RV1002Kt3neFz"
const STRIPE_API_URL = "pk_live_69PRfEciFHRxPmjdWagfqFSa00y82lPglV"


export default function OrderPage({match}) {

    function componentDidMount() { document.title = 'FlipFlip'; };

    // Pay button sends order to Stripe 
    function clickHandler() {
        console.log(order.order.id);
        let body = {'id':order.id,
            'title': order.title,
            'order': order.order,
        };
        console.log(body);
        console.log("OrderPage clickHandler() body.id: " + body.id);
        console.log("OrderPage clickHandler() body.title: " + body.title);
        console.log("OrderPage clickHandler() body.order: " + body.order);
        axios.put(FLP_API_CHECKOUT_URL, body).then(response => {
            console.log(response.data);
            console.log(response.data.body);
            console.log( JSON.parse(response.data.body) );
            console.log( JSON.parse(response.data.body).checkout_session_id.id );
            var checkout_session_id = JSON.parse(response.data.body).checkout_session_id.id;
            // DO NOT REMOVE the commented line below: compiler instruction
            /* global Stripe */
            var stripe = Stripe(STRIPE_API_URL);
            stripe.redirectToCheckout({
                sessionId: checkout_session_id 
            }).then(function (result) {
            console.log("Error: Cannot reach Checkout API");
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
            });
        });
    };


    let location = useLocation();
    console.log(location);
    console.log("Passed from PreviewPage - Id: "+location.state.id);
    console.log("Passed from PreviewPage - Title: "+location.state.title);

    const [order, setOrder] = React.useState( { order:{
                                                    'quantity': 1, 
                                                    'first_name': "",
                                                    'last_name': "",
                                                    'address_1': "",
                                                    'address_2': "",
                                                    'city': "",
                                                    'postal_code': "",
                                                    'country': "",
                                                }
    });
    const [quantity, setQuantity] = React.useState(1);
    const [checkout, setCheckout] = React.useState(false);
    const [showForm, setshowForm] = React.useState(true);

    const [isSticky, setSticky] = useState(false)

  const { control, register, handleSubmit } = useForm()


  console.log("///////order: " + order.order.quantity);
  console.log("///////order: " + order.order.first_name);
  var orderinfo={};


  //Checkout
  const onSubmit = data => {
    setQuantity(data.quantity);
    setCheckout(true);

    console.log(data);

    orderinfo = {
                id: location.state.id,
                title: location.state.title,
                order:{
                        'quantity': data.quantity,
                        'first_name': data.firstname,
                        'last_name': data.lastname, 
                        'address_1': data.shippingaddress1, 
                        'address_2': data.shippingaddress2, 
                        'city': data.city, 
                        'postal_code': data.postalcode, 
                        'country': data.country,
                }
    };

    setOrder( orderinfo );
    setshowForm(false);
 };

    const stickyContainer = useRef(null);
    const stickyElement = useRef(null);

    // This function handle the scroll performance issue
    const debounce = (func, wait = 20, immediate = true) => {
        let timeOut
        return () => {
            let context = this,
                args = arguments
            const later = () => {
                timeOut = null
                if (!immediate) func.apply(context, args)
            }
            const callNow = immediate && !timeOut
            clearTimeout(timeOut)
            timeOut = setTimeout(later, wait)
            if (callNow) func.apply(context, args)
        }
    }

    const handleScroll = (e) => {
        if(  stickyContainer.current.getBoundingClientRect().bottom - stickyElement.current.offsetHeight - 175 > 0){
            setSticky(true)
        }else{
            setSticky(false)
        }

    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    const formCheckout = !checkout && (
      <form id="order_form" className={"order-form"} onSubmit={handleSubmit(onSubmit)} >

          <Container style={{ padding: '16px 45px'}}>
              <Grid
                  container
                  spacing={3}
              >
                  <Grid item xs={"12"} >
                      <TextField fullWidth margin="normal" name="quantity" id="quantity" inputRef={register({required: true})} required label="Quantity" type="number" defaultValue="1" variant="filled" onChange={event => setQuantity(event.target.value)}/>
                  </Grid>
                  <Grid item xs={"12"} >
                      <TextField fullWidth name="firstname" id="first-name" inputRef={register({required: true})} required  label="First name" type="text" defaultValue="" variant="filled" />
                  </Grid>
                  <Grid item xs={"12"} >
                      <TextField fullWidth name="lastname" id="last-name" inputRef={register({required: true})} required  label="Last name" type="text" defaultValue="" variant="filled" />
                  </Grid>
                  <Grid item xs={"12"} >
                      <TextField fullWidth name="shippingaddress1" id="shipping-address-1" inputRef={register({required: true})} required  label="Address 1" helperText="(street, house nb)" type="text" defaultValue="" variant="filled" />
                  </Grid>
                  <Grid item xs={"12"} >
                      <TextField fullWidth name="shippingaddress2" id="shipping-address-2" label="Address 2" type="text" defaultValue="" helperText="apt / suit / unit (MenuItem al)" variant="filled" inputRef={register}/>
                  </Grid>
                  <Grid item xs={"12"} >
                      <TextField fullWidth name="city" required id="city" label="City" variant="filled" type="text" defaultValue="" inputRef={register({required: true})}/>
                  </Grid>
                  <Grid item xs={"12"} >
                      <TextField fullWidth name="postalcode" required id="postal-code" label="Postal code" type="text" defaultValue="" variant="filled" inputRef={register({required: true})}/><br/>
                  </Grid>
                  <Grid item xs={"12"} >
                      <Controller as={TextField} control={control} fullWidth name="country" required id="country" label="Country" type="text" defaultValue="FR" variant="filled" inputRef={register({required: true})} select>
                          <MenuItem value="AF">Afghanistan</MenuItem>
                          <MenuItem value="AX">Åland Islands</MenuItem>
                          <MenuItem value="AL">Albania</MenuItem>
                          <MenuItem value="DZ">Algeria</MenuItem>
                          <MenuItem value="AS">American Samoa</MenuItem>
                          <MenuItem value="AD">Andorra</MenuItem>
                          <MenuItem value="AO">Angola</MenuItem>
                          <MenuItem value="AI">Anguilla</MenuItem>
                          <MenuItem value="AQ">Antarctica</MenuItem>
                          <MenuItem value="AG">Antigua and Barbuda</MenuItem>
                          <MenuItem value="AR">Argentina</MenuItem>
                          <MenuItem value="AM">Armenia</MenuItem>
                          <MenuItem value="AW">Aruba</MenuItem>
                          <MenuItem value="AU">Australia</MenuItem>
                          <MenuItem value="AT">Austria</MenuItem>
                          <MenuItem value="AZ">Azerbaijan</MenuItem>
                          <MenuItem value="BS">Bahamas</MenuItem>
                          <MenuItem value="BH">Bahrain</MenuItem>
                          <MenuItem value="BD">Bangladesh</MenuItem>
                          <MenuItem value="BB">Barbados</MenuItem>
                          <MenuItem value="BY">Belarus</MenuItem>
                          <MenuItem value="BE">Belgium</MenuItem>
                          <MenuItem value="BZ">Belize</MenuItem>
                          <MenuItem value="BJ">Benin</MenuItem>
                          <MenuItem value="BM">Bermuda</MenuItem>
                          <MenuItem value="BT">Bhutan</MenuItem>
                          <MenuItem value="BO">Bolivia, Plurinational State of</MenuItem>
                          <MenuItem value="BQ">Bonaire, Sint Eustatius and Saba</MenuItem>
                          <MenuItem value="BA">Bosnia and Herzegovina</MenuItem>
                          <MenuItem value="BW">Botswana</MenuItem>
                          <MenuItem value="BV">Bouvet Island</MenuItem>
                          <MenuItem value="BR">Brazil</MenuItem>
                          <MenuItem value="IO">British Indian Ocean Territory</MenuItem>
                          <MenuItem value="BN">Brunei Darussalam</MenuItem>
                          <MenuItem value="BG">Bulgaria</MenuItem>
                          <MenuItem value="BF">Burkina Faso</MenuItem>
                          <MenuItem value="BI">Burundi</MenuItem>
                          <MenuItem value="KH">Cambodia</MenuItem>
                          <MenuItem value="CM">Cameroon</MenuItem>
                          <MenuItem value="CA">Canada</MenuItem>
                          <MenuItem value="CV">Cape Verde</MenuItem>
                          <MenuItem value="KY">Cayman Islands</MenuItem>
                          <MenuItem value="CF">Central African Republic</MenuItem>
                          <MenuItem value="TD">Chad</MenuItem>
                          <MenuItem value="CL">Chile</MenuItem>
                          <MenuItem value="CN">China</MenuItem>
                          <MenuItem value="CX">Christmas Island</MenuItem>
                          <MenuItem value="CC">Cocos (Keeling) Islands</MenuItem>
                          <MenuItem value="CO">Colombia</MenuItem>
                          <MenuItem value="KM">Comoros</MenuItem>
                          <MenuItem value="CG">Congo</MenuItem>
                          <MenuItem value="CD">Congo, the Democratic Republic of the</MenuItem>
                          <MenuItem value="CK">Cook Islands</MenuItem>
                          <MenuItem value="CR">Costa Rica</MenuItem>
                          <MenuItem value="CI">Côte d'Ivoire</MenuItem>
                          <MenuItem value="HR">Croatia</MenuItem>
                          <MenuItem value="CU">Cuba</MenuItem>
                          <MenuItem value="CW">Curaçao</MenuItem>
                          <MenuItem value="CY">Cyprus</MenuItem>
                          <MenuItem value="CZ">Czech Republic</MenuItem>
                          <MenuItem value="DK">Denmark</MenuItem>
                          <MenuItem value="DJ">Djibouti</MenuItem>
                          <MenuItem value="DM">Dominica</MenuItem>
                          <MenuItem value="DO">Dominican Republic</MenuItem>
                          <MenuItem value="EC">Ecuador</MenuItem>
                          <MenuItem value="EG">Egypt</MenuItem>
                          <MenuItem value="SV">El Salvador</MenuItem>
                          <MenuItem value="GQ">Equatorial Guinea</MenuItem>
                          <MenuItem value="ER">Eritrea</MenuItem>
                          <MenuItem value="EE">Estonia</MenuItem>
                          <MenuItem value="ET">Ethiopia</MenuItem>
                          <MenuItem value="FK">Falkland Islands (Malvinas)</MenuItem>
                          <MenuItem value="FO">Faroe Islands</MenuItem>
                          <MenuItem value="FJ">Fiji</MenuItem>
                          <MenuItem value="FI">Finland</MenuItem>
                          <MenuItem value="FR">France</MenuItem>
                          <MenuItem value="GF">French Guiana</MenuItem>
                          <MenuItem value="PF">French Polynesia</MenuItem>
                          <MenuItem value="TF">French Southern Territories</MenuItem>
                          <MenuItem value="GA">Gabon</MenuItem>
                          <MenuItem value="GM">Gambia</MenuItem>
                          <MenuItem value="GE">Georgia</MenuItem>
                          <MenuItem value="DE">Germany</MenuItem>
                          <MenuItem value="GH">Ghana</MenuItem>
                          <MenuItem value="GI">Gibraltar</MenuItem>
                          <MenuItem value="GR">Greece</MenuItem>
                          <MenuItem value="GL">Greenland</MenuItem>
                          <MenuItem value="GD">Grenada</MenuItem>
                          <MenuItem value="GP">Guadeloupe</MenuItem>
                          <MenuItem value="GU">Guam</MenuItem>
                          <MenuItem value="GT">Guatemala</MenuItem>
                          <MenuItem value="GG">Guernsey</MenuItem>
                          <MenuItem value="GN">Guinea</MenuItem>
                          <MenuItem value="GW">Guinea-Bissau</MenuItem>
                          <MenuItem value="GY">Guyana</MenuItem>
                          <MenuItem value="HT">Haiti</MenuItem>
                          <MenuItem value="HM">Heard Island and McDonald Islands</MenuItem>
                          <MenuItem value="VA">Holy See (Vatican City State)</MenuItem>
                          <MenuItem value="HN">Honduras</MenuItem>
                          <MenuItem value="HK">Hong Kong</MenuItem>
                          <MenuItem value="HU">Hungary</MenuItem>
                          <MenuItem value="IS">Iceland</MenuItem>
                          <MenuItem value="IN">India</MenuItem>
                          <MenuItem value="ID">Indonesia</MenuItem>
                          <MenuItem value="IR">Iran, Islamic Republic of</MenuItem>
                          <MenuItem value="IQ">Iraq</MenuItem>
                          <MenuItem value="IE">Ireland</MenuItem>
                          <MenuItem value="IM">Isle of Man</MenuItem>
                          <MenuItem value="IL">Israel</MenuItem>
                          <MenuItem value="IT">Italy</MenuItem>
                          <MenuItem value="JM">Jamaica</MenuItem>
                          <MenuItem value="JP">Japan</MenuItem>
                          <MenuItem value="JE">Jersey</MenuItem>
                          <MenuItem value="JO">Jordan</MenuItem>
                          <MenuItem value="KZ">Kazakhstan</MenuItem>
                          <MenuItem value="KE">Kenya</MenuItem>
                          <MenuItem value="KI">Kiribati</MenuItem>
                          <MenuItem value="KP">Korea, Democratic People's Republic of</MenuItem>
                          <MenuItem value="KR">Korea, Republic of</MenuItem>
                          <MenuItem value="KW">Kuwait</MenuItem>
                          <MenuItem value="KG">Kyrgyzstan</MenuItem>
                          <MenuItem value="LA">Lao People's Democratic Republic</MenuItem>
                          <MenuItem value="LV">Latvia</MenuItem>
                          <MenuItem value="LB">Lebanon</MenuItem>
                          <MenuItem value="LS">Lesotho</MenuItem>
                          <MenuItem value="LR">Liberia</MenuItem>
                          <MenuItem value="LY">Libya</MenuItem>
                          <MenuItem value="LI">Liechtenstein</MenuItem>
                          <MenuItem value="LT">Lithuania</MenuItem>
                          <MenuItem value="LU">Luxembourg</MenuItem>
                          <MenuItem value="MO">Macao</MenuItem>
                          <MenuItem value="MK">Macedonia, the former Yugoslav Republic of</MenuItem>
                          <MenuItem value="MG">Madagascar</MenuItem>
                          <MenuItem value="MW">Malawi</MenuItem>
                          <MenuItem value="MY">Malaysia</MenuItem>
                          <MenuItem value="MV">Maldives</MenuItem>
                          <MenuItem value="ML">Mali</MenuItem>
                          <MenuItem value="MT">Malta</MenuItem>
                          <MenuItem value="MH">Marshall Islands</MenuItem>
                          <MenuItem value="MQ">Martinique</MenuItem>
                          <MenuItem value="MR">Mauritania</MenuItem>
                          <MenuItem value="MU">Mauritius</MenuItem>
                          <MenuItem value="YT">Mayotte</MenuItem>
                          <MenuItem value="MX">Mexico</MenuItem>
                          <MenuItem value="FM">Micronesia, Federated States of</MenuItem>
                          <MenuItem value="MD">Moldova, Republic of</MenuItem>
                          <MenuItem value="MC">Monaco</MenuItem>
                          <MenuItem value="MN">Mongolia</MenuItem>
                          <MenuItem value="ME">Montenegro</MenuItem>
                          <MenuItem value="MS">Montserrat</MenuItem>
                          <MenuItem value="MA">Morocco</MenuItem>
                          <MenuItem value="MZ">Mozambique</MenuItem>
                          <MenuItem value="MM">Myanmar</MenuItem>
                          <MenuItem value="NA">Namibia</MenuItem>
                          <MenuItem value="NR">Nauru</MenuItem>
                          <MenuItem value="NP">Nepal</MenuItem>
                          <MenuItem value="NL">Netherlands</MenuItem>
                          <MenuItem value="NC">New Caledonia</MenuItem>
                          <MenuItem value="NZ">New Zealand</MenuItem>
                          <MenuItem value="NI">Nicaragua</MenuItem>
                          <MenuItem value="NE">Niger</MenuItem>
                          <MenuItem value="NG">Nigeria</MenuItem>
                          <MenuItem value="NU">Niue</MenuItem>
                          <MenuItem value="NF">Norfolk Island</MenuItem>
                          <MenuItem value="MP">Northern Mariana Islands</MenuItem>
                          <MenuItem value="NO">Norway</MenuItem>
                          <MenuItem value="OM">Oman</MenuItem>
                          <MenuItem value="PK">Pakistan</MenuItem>
                          <MenuItem value="PW">Palau</MenuItem>
                          <MenuItem value="PS">Palestinian Territory, Occupied</MenuItem>
                          <MenuItem value="PA">Panama</MenuItem>
                          <MenuItem value="PG">Papua New Guinea</MenuItem>
                          <MenuItem value="PY">Paraguay</MenuItem>
                          <MenuItem value="PE">Peru</MenuItem>
                          <MenuItem value="PH">Philippines</MenuItem>
                          <MenuItem value="PN">Pitcairn</MenuItem>
                          <MenuItem value="PL">Poland</MenuItem>
                          <MenuItem value="PT">Portugal</MenuItem>
                          <MenuItem value="PR">Puerto Rico</MenuItem>
                          <MenuItem value="QA">Qatar</MenuItem>
                          <MenuItem value="RE">Réunion</MenuItem>
                          <MenuItem value="RO">Romania</MenuItem>
                          <MenuItem value="RU">Russian Federation</MenuItem>
                          <MenuItem value="RW">Rwanda</MenuItem>
                          <MenuItem value="BL">Saint Barthélemy</MenuItem>
                          <MenuItem value="SH">Saint Helena, Ascension and Tristan da Cunha</MenuItem>
                          <MenuItem value="KN">Saint Kitts and Nevis</MenuItem>
                          <MenuItem value="LC">Saint Lucia</MenuItem>
                          <MenuItem value="MF">Saint Martin (French part)</MenuItem>
                          <MenuItem value="PM">Saint Pierre and Miquelon</MenuItem>
                          <MenuItem value="VC">Saint Vincent and the Grenadines</MenuItem>
                          <MenuItem value="WS">Samoa</MenuItem>
                          <MenuItem value="SM">San Marino</MenuItem>
                          <MenuItem value="ST">Sao Tome and Principe</MenuItem>
                          <MenuItem value="SA">Saudi Arabia</MenuItem>
                          <MenuItem value="SN">Senegal</MenuItem>
                          <MenuItem value="RS">Serbia</MenuItem>
                          <MenuItem value="SC">Seychelles</MenuItem>
                          <MenuItem value="SL">Sierra Leone</MenuItem>
                          <MenuItem value="SG">Singapore</MenuItem>
                          <MenuItem value="SX">Sint Maarten (Dutch part)</MenuItem>
                          <MenuItem value="SK">Slovakia</MenuItem>
                          <MenuItem value="SI">Slovenia</MenuItem>
                          <MenuItem value="SB">Solomon Islands</MenuItem>
                          <MenuItem value="SO">Somalia</MenuItem>
                          <MenuItem value="ZA">South Africa</MenuItem>
                          <MenuItem value="GS">South Georgia and the South Sandwich Islands</MenuItem>
                          <MenuItem value="SS">South Sudan</MenuItem>
                          <MenuItem value="ES">Spain</MenuItem>
                          <MenuItem value="LK">Sri Lanka</MenuItem>
                          <MenuItem value="SD">Sudan</MenuItem>
                          <MenuItem value="SR">Suriname</MenuItem>
                          <MenuItem value="SJ">Svalbard and Jan Mayen</MenuItem>
                          <MenuItem value="SZ">Swaziland</MenuItem>
                          <MenuItem value="SE">Sweden</MenuItem>
                          <MenuItem value="CH">Switzerland</MenuItem>
                          <MenuItem value="SY">Syrian Arab Republic</MenuItem>
                          <MenuItem value="TW">Taiwan, Province of China</MenuItem>
                          <MenuItem value="TJ">Tajikistan</MenuItem>
                          <MenuItem value="TZ">Tanzania, United Republic of</MenuItem>
                          <MenuItem value="TH">Thailand</MenuItem>
                          <MenuItem value="TL">Timor-Leste</MenuItem>
                          <MenuItem value="TG">Togo</MenuItem>
                          <MenuItem value="TK">Tokelau</MenuItem>
                          <MenuItem value="TO">Tonga</MenuItem>
                          <MenuItem value="TT">Trinidad and Tobago</MenuItem>
                          <MenuItem value="TN">Tunisia</MenuItem>
                          <MenuItem value="TR">Turkey</MenuItem>
                          <MenuItem value="TM">Turkmenistan</MenuItem>
                          <MenuItem value="TC">Turks and Caicos Islands</MenuItem>
                          <MenuItem value="TV">Tuvalu</MenuItem>
                          <MenuItem value="UG">Uganda</MenuItem>
                          <MenuItem value="UA">Ukraine</MenuItem>
                          <MenuItem value="AE">United Arab Emirates</MenuItem>
                          <MenuItem value="GB">United Kingdom</MenuItem>
                          <MenuItem value="US">United States</MenuItem>
                          <MenuItem value="UM">United States Minor Outlying Islands</MenuItem>
                          <MenuItem value="UY">Uruguay</MenuItem>
                          <MenuItem value="UZ">Uzbekistan</MenuItem>
                          <MenuItem value="VU">Vanuatu</MenuItem>
                          <MenuItem value="VE">Venezuela, Bolivarian Republic of</MenuItem>
                          <MenuItem value="VN">Viet Nam</MenuItem>
                          <MenuItem value="VG">Virgin Islands, British</MenuItem>
                          <MenuItem value="VI">Virgin Islands, U.S.</MenuItem>
                          <MenuItem value="WF">Wallis and Futuna</MenuItem>
                          <MenuItem value="EH">Western Sahara</MenuItem>
                          <MenuItem value="YE">Yemen</MenuItem>
                          <MenuItem value="ZM">Zambia</MenuItem>
                          <MenuItem value="ZW">Zimbabwe</MenuItem>
                      </Controller>
                  </Grid>
              </Grid>
              <br/>
          </Container>
      </form>
    )

    const addressCheckout = checkout && (
        <React.Fragment>
            <div className={"order-address"}>
                <h2 >Shipping address</h2>
                <p>{order.order.first_name} {order.order.last_name}<br/>
                {order.order.address_1} {order.order.address_2}<br/>
                {order.order.postal_code} {order.order.city}<br/>
                {order.order.country}</p>
            </div>

            <div class={"payment-box"}>
                <div>
                    <button onClick={clickHandler}>Proceed to payment</button>
                </div>
            </div>
        </React.Fragment>
    )

  return (
    <div ref={stickyContainer} className={"section-order "+(addressCheckout&&'is-checkout')}>
        <div ref={stickyElement} className={"order-info "+(isSticky?"is-sticky":"is-not-stcky")} >
            <div className={"cart"}>
                <h2>Order</h2>
                <p>Items: <span className={"highlight"}>x{quantity}</span> Flipbooks at <span
                    className={"highlight"}>12.99 €</span><br/>
                    Shipping: <span className={"highlight"}>3.70 €</span><br/>
                    <b>TOTAL: <span className={"highlight"}>{(quantity * 12.99 + 3.70).toFixed(2)} €</span></b></p>
            </div>
            {addressCheckout}
            {!addressCheckout&&(
                <button form='order_form' variant="extended" className={"button"} type="submit" value="Checkout">Checkout</button>
            )}

        </div>


      {formCheckout}



    </div>




  );
};
