import React, { Component,useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid';
import { ReactComponent as ArrowRight } from '../media/arrow_right.svg'
import {Link, Route, Switch} from "react-router-dom";
import FlipCascade from './../media/FlipCascade.jpg';
import FAQ from './../components/FAQ';
import Tips from './../components/Tips';
import Testimonial from './../components/Testimonial';
import background_form from "../media/background_form_07.svg";
import VideoAutoplay from "../components/VideoAutoplay";
import video_flipflip from "../media/flipflip-video.mp4";
import CustomHelmet from "../components/CustomHelmet";

const MainPage = () => {

    /*
useEffect(() =>{
    TweenMax.fromTo(videoRef, 2, {x:'-100%'}, {x:'0%'});
})*/

    let videoRef = null;

    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
    };


    const videoParentRef = useRef();
    const [shouldUseImage, setShouldUseImage] = useState(false);
    useEffect(() => {
        // check if user agent is safari and we have the ref to the container <div />
        if (isSafari() && videoParentRef.current) {
            // obtain reference to the video element
            const player = videoParentRef.current.children[0];

            // if the reference to video player has been obtained
            if (player) {
                // set the video attributes using javascript as per the
                // webkit Policy
                player.controls = false;
                player.playsinline = true;
                player.muted = true;
                player.setAttribute("muted", ""); // leave no stones unturned :)
                player.autoplay = true;

                // Let's wait for an event loop tick and be async.
                setTimeout(() => {
                    // player.play() might return a promise but it's not guaranteed crossbrowser.
                    const promise = player.play();
                    // let's play safe to ensure that if we do have a promise
                    if (promise.then) {
                        promise
                            .then(() => {})
                            .catch(() => {
                                // if promise fails, hide the video and fallback to <img> tag
                                videoParentRef.current.style.display = "none";
                                setShouldUseImage(true);
                            });
                    }
                }, 0);
            }
        }
    }, []);

    const videoAutoPlay = shouldUseImage ? (
        <img src={video_flipflip} alt="Muted Video" />
    ) : (
        <div
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
                __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
        >
        <source src="${video_flipflip}" type="video/mp4" />
        </video>`
            }}
        />
    );


        return (
            <React.Fragment >

                <CustomHelmet pageTitle={"FlipFlip - Turn your video into a fun and impressive Flipbook"}
                              pageDescription={"FlipFlip are beautiful printed Flipbooks from your videos. The best gift for yourself or your loved ones. "}
                              pageImage={"/presentation.jpg"}
                />

                <Link className={"button btn-sticky"} to="/start">Let's Start</Link>
                <div className={"first_row"}>
                    <Grid container spacing={0}>
                        <Grid item md={6} xs={12}>
                            {/*** Video ***/}
                            <div className={"player-video"}>
                                <VideoAutoplay src={video_flipflip} />
                            </div>
                        </Grid>

                        <Grid item md={6} xs={12} >
                            {/*** Presentation ***/}
                            <div className={"section section-presentation primary-container"}>
                                <div className={"secondary-container"} >
                                    <h2>
                                        Your best moments
                                        <div>FOREVER</div>
                                    </h2>
                                    <div className={"description"}>
                                        <p>
                                            <b>Turn your videos into a fun and impressive Flipbook.</b><br/>
                                            Your original gift, invitation, or announcement,<br/> for only <span style={{color:"#FF6767"}}>12.99 euros</span>.<br/>
                                            FlipFlips are printed on eco-friendly premium paper, allowing you to cherish the best moments of your life forever.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={0} className={"second-row"}>
                    <Grid item xs={12} md={6}>
                        <div className={'image-deco-first'}>
                            <img src={FlipCascade} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/*** How it Works? ***/}
                        <div className={"section section-how-it-work primary-container"} >
                            <div className={"secondary-container"} >
                                <h2>
                                    How to create your Flipbook?
                                </h2>
                                <ol className={"bullet-number"}>
                                    <li><b>Upload</b> your vidéo</li>
                                    <li><b>Write</b> your title</li>
                                    <li><b>Place</b> your order with flipflip’s<br/>
                                        secured payment gateway</li>
                                    <li><b>Receive</b> your order and start to flip !</li>
                                </ol>
                                <div className={"highlight-link-container"}>
                                        <Link to="/start" >Let's start <ArrowRight/></Link>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                {/*** Tips ***/}
                <div className={"section-tips primary-container"}>
                    <div className={"secondary-container"}>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                            <Grid item md={6}>
                                <h2 >
                                    Our<span> tips</span> !
                                </h2>

                                <div className={"highlight-link-container"}>
                                    <Link to={"/tips"} >More Tips<ArrowRight /></Link>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <Tips/>
                            </Grid>
                        </Grid>

                    </div>

                    <img className={"background-container"} src={background_form} />
                </div>


                {/*** How it Works? ***/}
                <Testimonial></Testimonial>

                {/*** FAQ ***/}
                <div className={"section-faq primary-container"}>
                    <div className={"secondary-container"}>
                        <Grid container direction="row" justify="center" alignItems="center" spacing={0}>
                            <Grid item xs={12} md={6}>
                                <h2>
                                    Frequently<br/>
                                    asked<br/>
                                    <div> questions</div>
                                </h2>

                                <div className={"highlight-link-container"}>
                                    <Link to={"/faq"} >More<ArrowRight /></Link>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FAQ qty_desktop={5} qty_mobile={3} />
                            </Grid>
                        </Grid>
                    </div>
                </div>

            </React.Fragment>

        );

}
export default MainPage;
