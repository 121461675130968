import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import background_form from './../media/background_form_07.svg'
import { ReactComponent as ArrowRight } from '../media/arrow_right.svg'
import { Grid } from '@material-ui/core';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive'
import CreatePage from "../pages/CreatePage";



var H1 = { color: "#000033", fontWeight: "900" };

var FaqQ = { fontWeight: "600", paddingTop: "20px" };
var FaqA = {};
var Section = {
    marginTop: "32px",
    marginBottom: "64px",
    marginLeft: "16px",
    marginRight: "16px"
};



class Tips extends React.Component {

    state = {
        Tips:[
            {
                title:"Slow motion video is a great idea",
                picto:'/picto/picto-slow-motion.svg'
            },
            {
                title:"Suggested video length: 4 to 15 seconds",
                picto:'/picto/picto-chrono.svg'
            },
            {
                title:"Isn’t it the best Gift idea?",
                picto:'/picto/picto-gift.svg'
            },
            {
                title:"Make sure there is some movement",
                picto:'/picto/picto-runner.svg'
            }

        ]

    }
    render() {

        function PrevButton({ onClick }) {
            return <div onClick={onClick} className="custom-slick-arrow custom-slick-prev" >
                <span></span>
                <span></span>
            </div>;
        }

        function NextButton({ onClick }) {
            return <div onClick={onClick} className="custom-slick-arrow custom-slick-next" >
                <span></span>
                <span></span>
            </div>;
        }

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            slidesPerRow: 2,
            rows: 2,
            arrows: true,
            nextArrow: <PrevButton/>,
            prevArrow: <NextButton/>,
            responsive: [{
                breakpoint: 960,
                settings: {
                    slidesPerRow: 1,
                    rows: 1,
                }
            }]
        };




        const faq_items = this.state.Tips.map((item, i) => {
            return (
                <div key={i+"tips-item"} className={"box-container"} >
                    <div className={"box"}>
                        <div className={"picto"}>
                            <img src={item.picto} />
                        </div>
                        <div className={"title"}>
                            {item.title}
                        </div>
                    </div>
                </div>

            );
        });

        return (
            <div className={"custom-slick-container tips-component"}>
                <Slider className={"custom-slider tips-slider"} {...settings}>
                    {faq_items}
                </Slider>
            </div>
        );
    }
}

export default Tips;