import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icon_trees from './../media/icon-trees.svg';
import icon_landscape from './../media/icon-landscape-portrait.svg';
import icon_flipbook from './../media/icon-flipbook.svg';
import icon_delivery from './../media/icon-delivery.svg';
import Grid from "@material-ui/core/Grid";


class Reinsurance extends React.Component {



    state = {
        Reinsurance:[
            {
                image:icon_flipbook,
                alt:"Icon flipbook",
                message: (
                <React.Fragment>
                    <p className={"textBlueSmall"}>
                        <span className={"textRed"}>48</span> pages Flipbook<br/>
                        <span className={"textRed"}>10.5</span> x <span className={"textRed"}>12.5</span> cm
                    </p>
                </React.Fragment>)
            },
            {
                image:icon_landscape,
                alt:"Icon landscape portrait",
                message: (
                    <React.Fragment>
                        <p className={"textBlueSmall"}>
                            Portrait & Landscape <br/><span className={"textRed"}> accepted</span>
                        </p>
                    </React.Fragment>)
            },
            {
                image:icon_delivery,
                alt:"Icon delivery",
                message: (
                    <React.Fragment>
                        <p className={"textBlueSmall"}>
                            Worldwide<span className={"textRed"}><br/> delivery</span>
                        </p>
                    </React.Fragment>)
            },
            {
                image:icon_trees,
                alt:"Icon trees",
                message: (
                    <React.Fragment>
                    <p className={"textBlueSmall"}>
                        Paper from <span className={"textRed"}> well managed<br/>
                        forests</span>Printed in Germany
                    </p>
                </React.Fragment>)
            },


        ]

    }

    render() {



        const reinsurance_items = this.state.Reinsurance.map((item, i) => {
            return (
                <Grid key={i+"reinsurance-item"}item>
                    <div className={"reinsurance-box"}>
                        <div className={"picto-box reinsurance"} >
                            <img src={item.image} alt={item.alt}
                                 style={{width: "100px", height: "100px", padding: "10px"}}/>
                            {item.message}
                        </div>
                    </div>
                </Grid>
            )
        });


        return (
            <Box className={"section section-reinsurance"} p={8} >
                <Grid container
                      direction="row"
                      justify="space-between"
                      alignItems="baseline"
                >
                    {reinsurance_items}
                </Grid>
            </Box>

        )

    }
}

export default Reinsurance;